// App - Types
import type { RequirementSeverity } from '../requirement-severity';

export const RequirementSeveritiesDto = ['High', 'Medium', 'Low', 'UNKNOWN'] as const;

export type RequirementSeverityDto = (typeof RequirementSeveritiesDto)[number];

export const castRequirementSeverityDtoToRequirementSeverity = (
  dto: RequirementSeverityDto
): RequirementSeverity => {
  if (dto === 'High') return 'high';

  if (dto === 'Medium') return 'medium';

  if (dto === 'Low') return 'low';

  return 'UNKNOWN';
};

export const castRequirementSeverityToRequirementSeverityDto = (
  severity: RequirementSeverity
): RequirementSeverityDto => {
  if (severity === 'high') return 'High';

  if (severity === 'medium') return 'Medium';

  if (severity === 'low') return 'Low';

  return 'UNKNOWN';
};

export const castRequirementSeverityScoreDtoToRequirementSeverity = (
  dto: number
): RequirementSeverity => {
  if (dto <= 2) return 'low';

  if (dto <= 4) return 'medium';

  if (dto <= 5) return 'high';

  return 'UNKNOWN';
};

export const castRequirementSeverityToRequirementSeverityScoreDto = (
  severity: RequirementSeverity
): number => {
  if (severity === 'low') return 2;

  if (severity === 'medium') return 4;

  if (severity === 'high') return 5;

  return 0;
};
