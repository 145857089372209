// React & Next
import { createElement } from 'react';

// App - Types
import type { ApplicationProgrammingLanguage } from '@/types/application';
import type { IconProps } from '@/components/atoms/icon';

// App - Other
import {
  DockerIcon,
  FileIcon,
  JsonIcon,
  PowerShellIcon,
  SettingsIcon,
  TomlIcon,
  VisualBasicIcon,
  YamlIcon,
  AngularIcon,
  CIcon,
  CPPIcon,
  CSharpIcon,
  CSSIcon,
  DartIcon,
  GoIcon,
  GroovyIcon,
  HTMLIcon,
  JavaIcon,
  JavaScriptIcon,
  KotlinIcon,
  LuaIcon,
  MatlabIcon,
  ObjectiveCIcon,
  PerlIcon,
  PhpIcon,
  PythonIcon,
  ReactIcon,
  RIcon,
  RubyIcon,
  RustIcon,
  SalesforceIcon,
  ScalaIcon,
  ServiceNowIcon,
  ShellIcon,
  SQLIcon,
  SwiftIcon,
  TerraformIcon,
  TypeScriptIcon,
  UnknownIcon,
  VueIcon,
} from '@/components/atoms/icon';
import { translateApplicationProgrammingLanguage } from './application-programming-language';

type ApplicationProgrammingLanguageIconProps = Partial<IconProps> & {
  lang: ApplicationProgrammingLanguage;
};

export const ApplicationProgrammingLanguageIcon = ({
  lang,
  ...iconProps
}: ApplicationProgrammingLanguageIconProps) => {
  return createElement(translateApplicationProgrammingLanguageToIcon(lang), {
    'aria-label': translateApplicationProgrammingLanguage(lang),
    ...iconProps,
  });
};

export const translateApplicationProgrammingLanguageToIcon = (
  lang: ApplicationProgrammingLanguage
) => {
  if (lang === 'angular') return AngularIcon;

  if (lang === 'c') return CIcon;

  if (lang === 'cpp') return CPPIcon;

  if (lang === 'csharp') return CSharpIcon;

  if (lang === 'css') return CSSIcon;

  if (lang === 'dart') return DartIcon;

  if (lang === 'dockerfile') return DockerIcon;

  if (lang === 'go') return GoIcon;

  if (lang === 'groovy') return GroovyIcon;

  if (lang === 'html') return HTMLIcon;

  if (lang === 'ini') return SettingsIcon;

  if (lang === 'java') return JavaIcon;

  if (lang === 'javascript') return JavaScriptIcon;

  if (lang === 'javascript_salesforce_lightning_components') return SalesforceIcon;

  if (lang === 'javascript_service_now_glide_api') return ServiceNowIcon;

  if (lang === 'json') return JsonIcon;

  if (lang === 'kotlin') return KotlinIcon;

  if (lang === 'lua') return LuaIcon;

  if (lang === 'makefile') return FileIcon;

  if (lang === 'markdown') return LuaIcon;

  if (lang === 'matlab') return MatlabIcon;

  if (lang === 'objective_c') return ObjectiveCIcon;

  if (lang === 'perl') return PerlIcon;

  if (lang === 'php') return PhpIcon;

  if (lang === 'python') return PythonIcon;

  if (lang === 'powershell') return PowerShellIcon;

  if (lang === 'r') return RIcon;

  if (lang === 'react') return ReactIcon;

  if (lang === 'ruby') return RubyIcon;

  if (lang === 'rust') return RustIcon;

  if (lang === 'scala') return ScalaIcon;

  if (lang === 'shell') return ShellIcon;

  if (lang === 'sql') return SQLIcon;

  if (lang === 'swift') return SwiftIcon;

  if (lang === 'terraform') return TerraformIcon;

  if (lang === 'typescript') return TypeScriptIcon;

  if (lang === 'toml') return TomlIcon;

  if (lang === 'visual_basic') return VisualBasicIcon;

  if (lang === 'vue') return VueIcon;

  if (lang === 'yaml') return YamlIcon;

  return UnknownIcon;
};
