// App - Types
import type { RequirementCountermeasureStatus } from '@/types/security-framework/requirement';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators['security-framework']['countermeasure-status'];

export const translateRequirementCountermeasureStatus = (
  status: RequirementCountermeasureStatus
): string => {
  if (status === 'approved') return locale['Approved'];

  if (status === 'suggested') return locale['Suggested'];

  if (status === 'under_review') return locale['Under review'];

  return locale['Unknown'];
};
