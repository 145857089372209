// App - Types
import type { ApplicationProgrammingLanguage } from '@/types/application';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.application['programming-language'];

export const translateApplicationProgrammingLanguage = (
  lang: ApplicationProgrammingLanguage
): string => {
  if (lang === 'angular') return locale['Angular'];

  if (lang === 'c') return locale['C'];

  if (lang === 'cpp') return locale['C++'];

  if (lang === 'csharp') return locale['C#'];

  if (lang === 'css') return locale['CSS'];

  if (lang === 'dart') return locale['Dart'];

  if (lang === 'dockerfile') return locale['Docker'];

  if (lang === 'go') return locale['Go'];

  if (lang === 'groovy') return locale['Groovy'];

  if (lang === 'html') return locale['HTML'];

  if (lang === 'ini') return locale['Ini'];

  if (lang === 'java') return locale['Java'];

  if (lang === 'javascript') return locale['JavaScript'];

  if (lang === 'javascript_salesforce_lightning_components')
    return locale['JavaScript Salesforce Lightning Components'];

  if (lang === 'javascript_service_now_glide_api') return locale['JavaScript ServiceNow Glide API'];

  if (lang === 'json') return locale['JSON'];

  if (lang === 'kotlin') return locale['Kotlin'];

  if (lang === 'lua') return locale['Lua'];

  if (lang === 'makefile') return locale['Makefile'];

  if (lang === 'markdown') return locale['Markdown'];

  if (lang === 'matlab') return locale['Matlab'];

  if (lang === 'objective_c') return locale['Objective C'];

  if (lang === 'perl') return locale['Perl'];

  if (lang === 'php') return locale['PHP'];

  if (lang === 'python') return locale['Python'];

  if (lang === 'powershell') return locale['PowerShell'];

  if (lang === 'r') return locale['R'];

  if (lang === 'react') return locale['React'];

  if (lang === 'ruby') return locale['Ruby'];

  if (lang === 'rust') return locale['Rust'];

  if (lang === 'scala') return locale['Scala'];

  if (lang === 'shell') return locale['Shell'];

  if (lang === 'sql') return locale['SQL'];

  if (lang === 'swift') return locale['Swift'];

  if (lang === 'terraform') return locale['Terraform'];

  if (lang === 'typescript') return locale['TypeScript'];

  if (lang === 'toml') return locale['Toml'];

  if (lang === 'visual_basic') return locale['Visual Basic'];

  if (lang === 'vue') return locale['Vue'];

  if (lang === 'yaml') return locale['Yaml'];

  return locale['Unknown'];
};
