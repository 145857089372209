// App - Types
import type { RequirementCountermeasureSource } from '@/types/security-framework/requirement';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators['security-framework']['countermeasure-source'];

export const translateRequirementCountermeasureSource = (
  source: RequirementCountermeasureSource
): string => {
  if (source === 'detected_in_code') return locale['Detected in code'];

  if (source === 'manual') return locale['Manual'];

  return locale['Unknown'];
};
