// App - Types
import type { ApplicationProgrammingLanguage } from '@/types/application';

export const translateApplicationProgrammingLanguageExtension = (
  lang: ApplicationProgrammingLanguage
): string => {
  if (lang === 'angular') return 'tsx';

  if (lang === 'c') return 'cpp';

  if (lang === 'cpp') return 'tsx';

  if (lang === 'csharp') return 'cpp';

  if (lang === 'css') return 'markup';

  if (lang === 'dart') return 'tsx';

  if (lang === 'dockerfile') return 'yaml';

  if (lang === 'go') return 'go';

  if (lang === 'groovy') return 'tsx';

  if (lang === 'html') return 'markup';

  if (lang === 'ini') return 'yaml';

  if (lang === 'java') return 'tsx';

  if (lang === 'javascript') return 'jsx';

  if (lang === 'javascript_salesforce_lightning_components') return 'jsx';

  if (lang === 'javascript_service_now_glide_api') return 'jsx';

  if (lang === 'json') return 'json';

  if (lang === 'kotlin') return 'kotlin';

  if (lang === 'lua') return 'tsx';

  if (lang === 'makefile') return 'tsx';

  if (lang === 'markdown') return 'markdown';

  if (lang === 'matlab') return 'tsx';

  if (lang === 'objective_c') return 'objective';

  if (lang === 'perl') return 'tsx';

  if (lang === 'php') return 'markup';

  if (lang === 'python') return 'python';

  if (lang === 'powershell') return 'tsx';

  if (lang === 'r') return 'tsx';

  if (lang === 'react') return 'jsx';

  if (lang === 'ruby') return 'tsx';

  if (lang === 'rust') return 'rust';

  if (lang === 'scala') return 'tsx';

  if (lang === 'shell') return 'tsx';

  if (lang === 'sql') return 'tsx';

  if (lang === 'swift') return 'swift';

  if (lang === 'terraform') return 'yaml';

  if (lang === 'typescript') return 'tsx';

  if (lang === 'toml') return 'yaml';

  if (lang === 'visual_basic') return 'tsx';

  if (lang === 'vue') return 'tsx';

  if (lang === 'yaml') return 'yaml';

  return 'tsx';
};
