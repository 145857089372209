// App - Types
import type { EntityType } from '@/types/entity';

export const COLLECTIONS_QUERY_KEYS = {
  root: ['collections'] as const,

  collections: () => [...COLLECTIONS_QUERY_KEYS.root] as const,
  collection: (entityType: EntityType, collectionId: string) =>
    [...COLLECTIONS_QUERY_KEYS.collections(), entityType, collectionId] as const,

  collectionsStats: () => [...COLLECTIONS_QUERY_KEYS.collections(), 'stats'] as const,

  relatedCollectionsStatsRecommendations: (collectionsIds: string[]) =>
    [...COLLECTIONS_QUERY_KEYS.collectionsStats(), 'related', collectionsIds.join(',')] as const,
};
