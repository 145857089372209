// App - Types
import type { ApplicationRiskProfile } from '@/types/application';

// App - Other
import Locale from '@/locale/en.json';

const locale = Locale.components.translators.application['risk-profile'];

export const translateApplicationRiskProfile = (riskProfile: ApplicationRiskProfile): string => {
  if (riskProfile === 'high') return locale['High'];

  if (riskProfile === 'medium') return locale['Medium'];

  if (riskProfile === 'low') return locale['Low'];

  return locale['Unknown'];
};
