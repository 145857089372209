// 3rd
import { inputAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers, useToken } from '@chakra-ui/react';

// App - Other
import { body, detail, paragraph } from '@/config/theme/components/text';
import { title } from '@/config/theme/components/heading';

const { definePartsStyle, defineMultiStyleConfig } = createMultiStyleConfigHelpers(parts.keys);

// ##########
// INPUT BASE
// ##########

const baseStyle = definePartsStyle({
  field: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 'sm',
    color: 'text.primary',
    h: 'fit-content',
    transition: 'all 180ms ease-in-out',

    '& .chakra-input__left-addon': {
      borderBottomLeftRadius: 'none',
      borderTopLeftRadius: 'none',
    },

    '& .chakra-input__right-addon': {
      borderBottomRightRadius: 'none',
      borderTopRightRadius: 'none',
    },
  },

  addon: {
    borderRadius: 'sm',
    h: 'fit-content',
  },
});

// #####
// SIZES
// #####

const smSize = definePartsStyle({
  group: {
    '--input-height': '36px',
  },

  field: {
    py: 'sm',
    px: 'md',
    ...body,
  },

  addon: {
    py: 'sm',
    px: 'md',
    ...body,
  },

  element: {
    '& .chakra-icon': {
      w: '18px',
      h: '18px',
    },
  },
});

const mdSize = definePartsStyle((props) => {
  const { variant } = props;

  return {
    group: {
      '--input-height': '44px',
    },

    field: {
      py: 'md',
      px: 'lg',
      ...(variant === 'outline' ? paragraph : title),
    },

    addon: {
      py: 'md',
      px: 'lg',
      ...(variant === 'outline' ? paragraph : title),
    },

    element: {
      '& .chakra-icon': {
        w: '22px',
        h: '22px',
      },
    },
  };
});

const lgSize = definePartsStyle({
  group: {
    '--input-height': '54px',
  },

  field: {
    py: 'lg',
    px: 'xl',
    ...title,
  },

  addon: {
    py: 'lg',
    px: 'xl',
    ...title,
  },

  element: {
    '& .chakra-icon': {
      w: '28px',
      h: '28px',
    },
  },
});

const oldSmSize = definePartsStyle({
  group: {
    '--input-height': '24px',
  },

  field: {
    py: '3px',
    px: 'md',
    ...detail,
  },

  addon: {
    py: '3px',
    px: 'sm',
    ...detail,
  },

  element: {
    '& button': {
      w: 'auto',
      h: 'auto',
      minW: 'auto',
      minH: 'auto',
    },

    '& .chakra-icon': {
      w: '14px',
      h: '14px',
    },
  },
});

const oldMdSize = definePartsStyle({
  group: {
    '--input-height': '32px',
  },

  field: {
    py: '6px',
    px: 'lg',
    ...body,
  },

  addon: {
    py: '6px',
    px: 'md',
    ...body,
  },

  element: {
    '& .chakra-icon': {
      w: '20px',
      h: '20px',
    },
  },
});

// ########
// VARIANTS
// ########

const outlineVariant = definePartsStyle(() => {
  const [borderColor, hoverBorderColor, focusBorderColor] = useToken('colors', [
    'border.primary',
    'border.high-tone',
    'border.brand.primary',
  ]);

  return {
    field: {
      bg: 'surface.primary',
      border: 'none',
      boxShadow: `0 0 0 1px ${borderColor} inset`,

      _placeholder: {
        color: 'text.mid-tone',
      },

      _hover: {
        boxShadow: `0 0 0 1px ${hoverBorderColor} inset`,
      },

      _focus: {
        boxShadow: `0 0 0 1px ${focusBorderColor} inset`,
      },
    },

    addon: {
      border: 'none',
      boxShadow: `0 0 0 1px ${borderColor} inset`,
      bg: 'surface.high-tone',
    },
  };
});

const ghostVariant = definePartsStyle({
  field: {
    bg: 'surface.primary',
    border: 'none',
    boxShadow: 'none',

    _placeholder: {
      color: 'text.secondary',
    },

    _hover: {
      bg: 'surface.high-tone',
    },

    _focus: {
      boxShadow: '0 0 0 1px var(--chakra-colors-border-brand-primary) inset',
    },
  },

  addon: {
    boxShadow: 'none',
    bg: 'surface.mid-tone',
  },
});

const oldOutlineVariant = definePartsStyle(() => {
  const [borderColor, hoverBorderColor, focusBorderColor] = useToken('colors', [
    'border.primary',
    'border.high-tone',
    'border.brand.primary',
  ]);

  return {
    field: {
      bg: 'surface.primary',
      border: 'none',
      boxShadow: `0 0 0 1px ${borderColor} inset`,

      _placeholder: {
        color: 'text.mid-tone',
      },

      _hover: {
        boxShadow: `0 0 0 1px ${hoverBorderColor} inset`,
      },

      _focus: {
        boxShadow: `0 0 0 1px ${focusBorderColor} inset`,
      },

      _disabled: {
        boxShadow: `0 0 0 1px ${hoverBorderColor} inset`,
      },
    },

    addon: {
      border: 'none',
      boxShadow: `0 0 0 1px ${borderColor} inset`,
      bg: 'surface.high-tone',
    },
  };
});

const oldFlushedVariant = definePartsStyle(() => {
  const [borderColor, hoverBorderColor, focusBorderColor] = useToken('colors', [
    'border.primary',
    'border.high-tone',
    'border.brand.primary',
  ]);

  return {
    field: {
      bg: 'surface.primary',
      border: 'none',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0',
      boxShadow: `0 -1px 0 0 ${borderColor} inset`,

      _placeholder: {
        color: 'text.mid-tone',
      },

      _hover: {
        boxShadow: `0 -1px 0 0 ${hoverBorderColor} inset`,
      },

      _focus: {
        boxShadow: `0 -1px 0 0 ${focusBorderColor} inset`,
      },
    },

    addon: {
      bg: 'surface.high-tone',
      border: 'none',
      borderBottomLeftRadius: '0',
      borderBottomRightRadius: '0',
      boxShadow: `0 -1px 0 0 ${borderColor} inset`,
    },
  };
});

const inputTheme = defineMultiStyleConfig({
  baseStyle,
  variants: {
    outline: outlineVariant,
    ghost: ghostVariant,
    'old.outline': oldOutlineVariant,
    'old.flushed': oldFlushedVariant,
  },
  sizes: {
    sm: smSize,
    md: mdSize,
    lg: lgSize,
    'old-sm': oldSmSize,
    'old-md': oldMdSize,
  },
});

export const Input = inputTheme;
