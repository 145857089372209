// 3rd
import { z } from 'zod';

// App - Types
import type { SendMissingInformationSlackMessage } from '../../../types/missing-information-feedback';

export const ZodSendMissingInformationSlackMessageDto = z.object({
  messagingAppUserId: z.string(),
  missingInformationIds: z.string().array(),
  securityStoryId: z.string(),
});

export type SendMissingInformationSlackMessageDto = z.infer<
  typeof ZodSendMissingInformationSlackMessageDto
>;

export const castSendMissingInformationSlackMessageToSendMissingInformationSlackMessageDto = (
  payload: SendMissingInformationSlackMessage
): SendMissingInformationSlackMessageDto => {
  return {
    messagingAppUserId: payload.recipient.id,
    missingInformationIds: payload.missingInformationIds || [],
    securityStoryId: payload.storyId,
  };
};
