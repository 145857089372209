// 3rd
import { AxiosError } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import { castEntityTypeToEntityTypeDto, EntityTypesDto } from '@/types/entity/dtos';
import type { CollectionStats } from '../types/collection';
import {
  castEntityCollectionStatsDtoToCollectionStats,
  ZodEntityCollectionStatsDto,
} from './dtos/collection-stats';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { COLLECTIONS_QUERY_KEYS } from '../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  sourceCollections: z
    .array(
      z.object({
        collectionId: z.string(),
        entityType: z.enum(EntityTypesDto),
      })
    )
    .default([]),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  recommendedCollections: z.array(ZodEntityCollectionStatsDto).default([]),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const getRelatedCollectionsStatsRecommendations = async (
  collectionsStats: Pick<CollectionStats, 'entityType' | 'id'>[]
): Promise<CollectionStats[]> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      sourceCollections: collectionsStats.map((c) => ({
        collectionId: c.id,
        entityType: castEntityTypeToEntityTypeDto(c.entityType),
      })),
    });
    const res = await apiClient.post(`/Collection/ListRecommendedCollections`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.recommendedCollections.map(castEntityCollectionStatsDtoToCollectionStats);
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return [];
    }

    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseRelatedCollectionsStatsRecommendations = {
  collectionsStats?: Pick<CollectionStats, 'entityType' | 'id'>[];
  enabled?: boolean;
};

export const useRelatedCollectionsStatsRecommendations = ({
  collectionsStats,
  enabled = true,
}: UseRelatedCollectionsStatsRecommendations = {}) => {
  const { data, isLoading, isFetching, isLoadingError } = useQuery({
    queryKey: COLLECTIONS_QUERY_KEYS.relatedCollectionsStatsRecommendations(
      (collectionsStats || []).map((c) => c.id)
    ),
    queryFn: async () => await getRelatedCollectionsStatsRecommendations(collectionsStats!),
    enabled: enabled && !!collectionsStats?.length,
  });

  return {
    relatedCollectionsStatsRecommendations: data,
    isFetchingRelatedCollectionsStatsRecommendations: isFetching,
    isLoadingRelatedCollectionsStatsRecommendations: isLoading,
    didFetchRelatedCollectionsStatsRecommendationsErrored: isLoadingError,
  };
};
