// App - Types
import type { ApplicationRiskProfile } from '@/types/application';

export const translateApplicationRiskProfileToColor = (
  riskProfile: ApplicationRiskProfile
): string => {
  if (riskProfile === 'high') return '#EC8C7E';

  if (riskProfile === 'medium') return '#FFCA45';

  if (riskProfile === 'low') return '#82B8FE';

  return '#d7e1ee';
};

export const translateApplicationRiskProfileToBgColor = (
  riskProfile: ApplicationRiskProfile
): string => {
  if (riskProfile === 'high') return '#FDE2E2';

  if (riskProfile === 'medium') return '#FDFAE2';

  if (riskProfile === 'low') return '#E2F0FD';

  return '#d7e1ee';
};
