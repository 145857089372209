// React & Next
import { useEffect, useMemo, useState } from 'react';

// 3rd
import { Skeleton, forwardRef, Flex } from '@chakra-ui/react';
import type { ChakraComponent, StyleProps } from '@chakra-ui/react';

// App - Types
import { getUniqueCollectionId } from '../../types/collection';
import type { CollectionStats } from '../../types/collection';

// App - Other
import { debounce } from '@/utils/debounce';
import { CheckIcon } from '@/components/atoms/icon';
import { AsyncSelect } from '@/components/molecules/form';
import { KnowledgeSourceIcon } from '@/components/translators/integration';
import { translateEntityTypeToScrappedEntity } from '@/components/translators/entity';

type CollectionsSelectCollectionComponent = ChakraComponent<
  'div',
  CollectionsSelectCollectionProps
> & {
  Loading: typeof Loading;
};

type CollectionsSelectCollectionProps = StyleProps & {
  collections: CollectionStats[];
  onLoadOptions: (inputValue: string) => Promise<CollectionStats[]>;
  placeholder?: string;
  selected?: Array<CollectionStats>;
  onChange: (collections?: Array<CollectionStats>) => void;
  onBlur?: () => void;
  isDisabled?: boolean;
  controlShouldRenderValue?: boolean;
};

export const AsyncSelectCollection = forwardRef(
  (
    {
      collections,
      onLoadOptions,
      placeholder,
      selected,
      onChange,
      onBlur,
      isDisabled,
      controlShouldRenderValue = true,
      ...props
    }: CollectionsSelectCollectionProps,
    ref
  ) => {
    const [collectionsStatsAudit, setCollectionsStatsAudit] = useState<CollectionStats[]>([]);

    const defaultOptions = useMemo(() => {
      return collections
        .map((collection) => {
          return {
            value: getUniqueCollectionId(collection),
            label: getUniqueCollectionId(collection),
          };
        })
        .sort((a, b) =>
          a.label.localeCompare(b.label, undefined, {
            numeric: true,
          })
        );
    }, [collections]);

    const handleLoadOptions = async (inputValue: string) => {
      if (!inputValue) {
        return [];
      }

      try {
        const collectionStats = await onLoadOptions(inputValue);

        setCollectionsStatsAudit((prevState) => {
          const extraCollectionsFromPrevState = prevState.filter(
            (prevCollection) =>
              !collectionStats.find(
                (collection) =>
                  getUniqueCollectionId(prevCollection) === getUniqueCollectionId(collection)
              )
          );

          return [...collectionStats, ...extraCollectionsFromPrevState];
        });

        return collectionStats
          .map((collection) => {
            return {
              value: getUniqueCollectionId(collection),
              label: getUniqueCollectionId(collection),
            };
          })
          .sort((a, b) =>
            a.label.localeCompare(b.label, undefined, {
              numeric: true,
            })
          );
      } catch (error) {
        console.error(error);

        return [];
      }
    };

    const selectedOptions = useMemo(() => {
      return (selected || []).map((collection) => ({
        value: getUniqueCollectionId(collection),
        label: getUniqueCollectionId(collection),
      }));
    }, [selected]);

    useEffect(() => {
      setCollectionsStatsAudit((prevState) => {
        const collectionsNotInPrevState = collections.filter(
          (collection) =>
            !prevState.find(
              (prevCollection) =>
                getUniqueCollectionId(prevCollection) === getUniqueCollectionId(collection)
            )
        );

        return [...prevState, ...collectionsNotInPrevState];
      });
    }, [collections]);

    useEffect(() => {
      setCollectionsStatsAudit((prevState) => {
        // Make sure all selected in audit or add
        const selectedNotInPrevState = (selected || []).filter(
          (selectedCollection) =>
            !prevState.find(
              (prevCollection) =>
                getUniqueCollectionId(prevCollection) === getUniqueCollectionId(selectedCollection)
            )
        );

        return [...prevState, ...selectedNotInPrevState];
      });
    }, [selected]);

    return (
      <AsyncSelect
        useBasicStyles
        variant="outline"
        size="sm"
        isMulti
        defaultOptions={defaultOptions}
        cacheOptions
        isDisabled={isDisabled}
        value={selectedOptions}
        selectedOptionColor="surface.brand.primary"
        placeholder={placeholder}
        onBlur={onBlur}
        controlShouldRenderValue={controlShouldRenderValue}
        loadOptions={debounce((inputValue, callback) => {
          handleLoadOptions(inputValue).then((options) => {
            callback(options);
          });
        }, 420)}
        isOptionSelected={() => false}
        onChange={(options) => {
          if (!options || !options.length) {
            return onChange([]);
          }

          const selectedCollections = Array.from(
            options.reduce((set, option) => {
              return set.add(option.value);
            }, new Set<string>())
          ).flatMap((collectionUniqueId) => {
            const collection = collectionsStatsAudit.find(
              (c) => getUniqueCollectionId(c) === collectionUniqueId
            );

            return collection ? [collection] : [];
          });

          onChange(selectedCollections);
        }}
        ref={ref}
        escapeClearsValue={false}
        backspaceRemovesValue={false}
        isSearchable={true}
        closeMenuOnSelect={true}
        hideSelectedOptions={false}
        isClearable={false}
        filterOption={() => true}
        formatOptionLabel={(option) => {
          const collection = collectionsStatsAudit.find(
            (c) => getUniqueCollectionId(c) === option.value
          );

          if (!collection) return null;

          const isSelected = selectedOptions.some(
            (selectedOption) => selectedOption.value === option.value
          );

          return (
            <Flex alignItems="center" gap="sm" w="100%">
              <KnowledgeSourceIcon source={collection.source} size="2xs" />

              {['github_pull_request', 'github_issue'].includes(collection.entityType)
                ? `(${translateEntityTypeToScrappedEntity(collection.entityType, { count: 0 })}) `
                : null}

              {collection.name}

              <Flex grow={1} />

              {isSelected ? (
                <CheckIcon aria-label="selected" size="2xs" color="text.primary" />
              ) : null}
            </Flex>
          );
        }}
        chakraStyles={{
          container: (styles) => ({ ...styles, ...props }),
          menuList: (styles) => ({ ...styles, ...props }),
        }}
      />
    );
  }
) as CollectionsSelectCollectionComponent;

type LoadingProps = StyleProps;

const Loading = ({ ...props }: LoadingProps) => {
  return <Skeleton {...props} height="26px" />;
};

AsyncSelectCollection.Loading = Loading;
