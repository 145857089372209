// App - Types
import type { ApplicationQuestionnaireGuidelinesStructure } from '@/types/application';

// App - Other
import Locale from '@/locale/en.json';

const locale =
  Locale.components.translators.application['application-questionnaire-guidelines-structure'];

export const translateApplicationQuestionnaireGuidelinesStructure = (
  structure: ApplicationQuestionnaireGuidelinesStructure
): string => {
  if (structure === 'data_flow_diagram') return locale['Data flow diagram'];

  if (structure === 'cloud_architecture') return locale['Cloud architecture'];

  if (structure === 'system_architecture_diagram') return locale['System architecture diagram'];

  return locale['Unknown'];
};
