export const styles = {
  global: {
    'html, body': {
      height: '100%',
      bg: 'surface.secondary',
    },

    body: {
      overflow: 'overlay',
    },

    '#__next': {
      height: '100%',
      bg: 'gray.50',
    },

    '*::placeholder': {
      color: 'gray.400',
    },
  },
};
