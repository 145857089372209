// 3rd
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { SendMissingInformationCommentFeedback } from '../../types/missing-information-feedback';
import {
  ZodSendMissingInformationCommentFeedbackDto,
  castSendMissingInformationCommentFeedbackToSendMissingInformationCommentFeedbackDto,
} from './dtos/send-missing-information-comment-feedback';

// App - Other
import { apiClient } from '@/config/lib/api-client';
import { queryClient } from '@/config/lib/react-query';
import { FEEDBACK_QUERY_KEYS } from '../../config/react-query-key-factory';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = ZodSendMissingInformationCommentFeedbackDto;

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

export { ZodRequestPayloadDto as ZodSendMissingInformationCommentFeedbackRequestPayloadDto };
export type { RequestPayloadDto as SendMissingInformationCommentFeedbackRequestPayloadDto };

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  securityFeedbackId: z.string(),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

export { ZodResponseDto as ZodSendMissingInformationCommentFeedbackResponseDto };
export type { ResponseDto as SendMissingInformationCommentFeedbackResponseDto };

// #######
// Request
// #######

export const sendMissingInformationCommentFeedback = async (
  storyId: string,
  model: SendMissingInformationCommentFeedback
): Promise<string> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse(
      castSendMissingInformationCommentFeedbackToSendMissingInformationCommentFeedbackDto(
        storyId,
        model
      )
    );
    const res = await apiClient.post(`/SecurityFeedback/CreateMissingInformationComment`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.securityFeedbackId;
  } catch (e) {
    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type UseSendMissingInformationCommentFeedback = {
  storyId: string;
  onStart?: () => void;
  onSuccess?: (payload: SendMissingInformationCommentFeedback) => void;
  onError?: (error: Error) => void;
  onFinish?: () => void;
};

export const useSendMissingInformationCommentFeedback = ({
  storyId,
  onStart,
  onSuccess,
  onError,
  onFinish,
}: UseSendMissingInformationCommentFeedback) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (sendCommentFeedbackPayload: SendMissingInformationCommentFeedback) => {
      await sendMissingInformationCommentFeedback(storyId, sendCommentFeedbackPayload);

      return sendCommentFeedbackPayload;
    },
    onMutate: () => onStart?.(),
    onSuccess: (payload) => onSuccess?.(payload),
    onError: (error) => onError?.(error),
    onSettled: (payload, error, sendCommentFeedbackPayload) => {
      queryClient.invalidateQueries({
        queryKey: FEEDBACK_QUERY_KEYS.entityFeedback(sendCommentFeedbackPayload.recipientEntityId),
      });

      onFinish?.();
    },
  });

  return {
    sendMissingInformationComment: mutate,
    isSendingMissingInformationComment: isPending,
    didSendMissingInformationCommentErrored: isError,
  };
};
