// 3rd
import { AxiosError } from 'axios';
import { useMutation } from '@tanstack/react-query';
import { z } from 'zod';

// App - Types
import type { CollectionStats } from '../types/collection';
import {
  castEntityCollectionStatsDtoToCollectionStats,
  ZodEntityCollectionStatsDto,
} from './dtos/collection-stats';

// App - Other
import { apiClient } from '@/config/lib/api-client';

// ###########
// Request DTO
// ###########

const ZodRequestPayloadDto = z.object({
  searchQuery: z.string().optional(),
});

type RequestPayloadDto = z.infer<typeof ZodRequestPayloadDto>;

// ############
// Response DTO
// ############

const ZodResponseDto = z.object({
  collectionsStats: z.array(ZodEntityCollectionStatsDto).default([]),
});

type ResponseDto = z.infer<typeof ZodResponseDto>;

// #######
// Request
// #######

export const queryCollectionsStats = async (query?: string): Promise<CollectionStats[]> => {
  try {
    const payload: RequestPayloadDto = ZodRequestPayloadDto.parse({
      searchQuery: query,
    });
    const res = await apiClient.post(`/Collection/QueryCollectionStats`, payload);
    const parsedRes: ResponseDto = ZodResponseDto.parse(res);

    return parsedRes.collectionsStats.map(castEntityCollectionStatsDtoToCollectionStats);
  } catch (e) {
    if (e instanceof AxiosError && e.response?.status === 404) {
      return [];
    }

    console.error(e);

    return Promise.reject(e);
  }
};

// ####
// Hook
// ####

type MutationPayload = {
  query?: string;
};

type UseQueryCollectionsStats = {
  onStart?: () => void;
  onSuccess?: () => void;
  onError?: (error: Error) => void;
};

export const useQueryCollectionsStats = ({
  onStart,
  onSuccess,
  onError,
}: UseQueryCollectionsStats = {}) => {
  const { mutate, isPending, isError } = useMutation({
    mutationFn: async (payload: MutationPayload) => await queryCollectionsStats(payload.query),
    onMutate: () => onStart?.(),
    onSuccess: () => onSuccess?.(),
    onError: (error) => onError?.(error),
  });

  return {
    queryCollectionsStats: mutate,
    isQueryingCollectionsStats: isPending,
    didQueryCollectionsStatsErrored: isError,
  };
};
